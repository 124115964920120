;
(function (global, factory) {
    if (typeof exports === "object" && typeof module !== "undefined") {
        module.exports = factory();
    }
    else if (typeof define === "function" && define.amd) {
        define(factory);
    }
    else {
        global.cardutil = factory();
    }
}(this, function () {
    "use strict";
    /*!
     * cardutil v1.x.x
     * Copyright 2017 Clearent, LLC
     */
    var _card_util = function () {
        "use strict";
        this.cardNumber = null;
        this.MIN_CARD_LENGTH = 13;
        this.MAX_CARD_LENGTH = 19;
        this.TYPES = {
            VISA: "Visa",
            MASTERCARD: "MasterCard",
            AMERICAN_EXPRESS: "American Express",
            DISCOVER: "Discover",
            DINERS_CLUB: "Diners Club",
            JCB: "JCB"
        };
        this.TRACK_FORMATS = {
            "TRACK2": {
                TRACK1_START_SENTINEL: "%",
                TRACK1_END_SENTINEL: "?",
                TRACK1_FIELD_SEPARATOR: "^",
                TRACK2_START_SENTINEL: ";",
                TRACK2_END_SENTINEL: "?",
                TRACK2_FIELD_SEPARATOR: "="
            },
            "IDTECH": {
                TRACK1_START_SENTINEL: "%",
                TRACK1_END_SENTINEL: "?",
                TRACK1_FIELD_SEPARATOR: "^",
                TRACK2_START_SENTINEL: ";",
                TRACK2_END_SENTINEL: "?",
                TRACK2_FIELD_SEPARATOR: "="
            },
            "MAGTEK": {
                TRACK1_START_SENTINEL: "%",
                TRACK1_END_SENTINEL: "?",
                TRACK1_FIELD_SEPARATOR: "^",
                TRACK2_START_SENTINEL: ";",
                TRACK2_END_SENTINEL: "?",
                TRACK2_FIELD_SEPARATOR: "="
            }
        };
    };
    _card_util.prototype.setCard = function (cardNumber) {
        this.cardNumber = cardNumber;
        return this;
    };
    _card_util.prototype.getCard = function () {
        return this.cardNumber;
    };
    _card_util.prototype.stripAlpha = function (cardNumber) {
        var v = cardNumber || this.cardNumber;
        return v.replace(/[^0-9]+/g, "");
    };
    _card_util.prototype.isValidLength = function (cardNumber) {
        var v = cardNumber || this.cardNumber;
        return v.length >= this.MIN_CARD_LENGTH && v.length <= this.MAX_CARD_LENGTH;
    };
    _card_util.prototype.format = function (cardNumber, separator) {
        var v = cardNumber || this.cardNumber;
        var sep = separator || "  ";
        var cardType = this.getCardType(v);
        if (null === v || v.length === 0 || !isStringNumeric(v)) {
            return v;
        }
        switch (cardType) {
            case this.TYPES.AMERICAN_EXPRESS:
            case this.TYPES.DINERS_CLUB:
                // #### ###### #%###[#####]
                if (v.length > 10) {
                    v = v.split(/(.{4})(.{6})(.*)/).filter(String).join(sep);
                }
                else if (v.length > 4) {
                    v = v.split(/(.{4})(.*)/).filter(String).join(sep);
                }
                break;
            case this.TYPES.MASTERCARD:
            case this.TYPES.VISA:
            case this.TYPES.DISCOVER:
            case this.TYPES.JCB:
            default:
                // #### #### #### ####
                v = v.split(/(....)/).filter(String).join(sep);
                break;
        }
        return v;
    };
    _card_util.prototype.getCardType = function (cardNumber, matchPartialNumber) {
        "use strict";
        var matchPartial = (typeof matchPartialNumber === "undefined") ? true : matchPartialNumber;
        var v = cardNumber || this.cardNumber;
        if (null === v || v.length === 0 || !isStringNumeric(v)) {
            return null;
        }
        var cardType = "";
        var re = {
            "visa": {
                type: this.TYPES.VISA,
                partial: /^4[0-9]/,
                full: /^4[0-9]{12}(?:[0-9]{3})?$/
            },
            "mastercard": {
                type: this.TYPES.MASTERCARD,
                partial: /^(?:5[1-5]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[0-1]|2720)/,
                full: /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/
            },
            "amex": {
                type: this.TYPES.AMERICAN_EXPRESS,
                partial: /^3[47]/,
                full: /^3[47][0-9]{13}$/
            },
            discover: {
                type: this.TYPES.DISCOVER,
                partial: /^6(011|5[0-9])/,
                full: /^6(?:011|5[0-9]{2})[0-9]{12}$/
            },
            dinersclub: {
                type: this.TYPES.DINERS_CLUB,
                partial: /^3(0[0-5]|[68])/,
                full: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/
            },
            jcb: {
                type: this.TYPES.JCB,
                partial: /^(2131|1800|35)/,
                full: /^(?:2131|1800|35\d{3})\d{11}$/
            }
        };
        var matchType = matchPartial ? "partial" : "full";
        for (var r in re) {
            if (re.hasOwnProperty(r)) {
                if (re[r][matchType].test(v)) {
                    return re[r].type;
                }
            }
        }
        return null;
    };
    _card_util.prototype.isExpired = function (month, year) {
        month = ~~month;
        year = ~~year;
        year = year <= 99 ? year + 2000 : year;
        return (new Date()) >= new Date(year, month);
    };
    _card_util.prototype.passesLuhn = function (cardNumber) {
        "use strict";
        /*
         Luhn algorithm to validate credit card by check digit
         From the rightmost digit, which is the check digit, moving left, double the value of every second digit;
         if the product of this doubling operation is greater than 9 (e.g., 8 x 2 = 16), then
         sum the digits of the product (e.g., 16: 1 + 6 = 7, 18: 1 + 8 = 9).
         or simply subtract 9 from the product (e.g., 16: 16 - 9 = 7, 18: 18 - 9 = 9).
         Take the sum of all the digits.
         If the total modulo 10 is equal to 0 (if the total ends in zero) then the number is valid according to the Luhn formula; else it is not valid.
         */
        var v = cardNumber || this.cardNumber;
        if (null === v || v.length === 0 || !isStringNumeric(v)) {
            return false;
        }
        var temp, total = 0, cardDigits = v.split(/(.)/).filter(isDigit);
        for (var j = 1, i = cardDigits.length - 1; i > -1; i--, j++) {
            // going in reverse, double every second digit
            if (j % 2 === 0) {
                temp = parseInt(cardDigits[i]) * 2;
                total += temp > 9 ? temp - 9 : temp;
            }
            else {
                total += parseInt(cardDigits[i]);
            }
        }
        return total % 10 === 0;
    };
    _card_util.prototype.parseSwipe = function (trackFormat, data) {
        var swipeInfo = {};
        swipeInfo.trackFormat = trackFormat;
        swipeInfo.magData = data;
        swipeInfo.errors = [];
        swipeInfo.messages = [];
        if (null === trackFormat || typeof this.TRACK_FORMATS[swipeInfo.trackFormat] === "undefined") {
            swipeInfo.errors.push("Invalid trackFormat specified [" + swipeInfo.trackFormat + "].");
            return swipeInfo;
        }
        if (null === data || typeof data === "undefined" || data === "") {
            swipeInfo.errors.push("Card swipe not read.");
            return swipeInfo;
        }
        var hasTrack1 = data.indexOf("^") > 0;
        var hasTrack2 = data.indexOf("=") > 0;
        if (hasTrack1) {
            swipeInfo = this.addTrack1Data(swipeInfo);
        }
        if (hasTrack2) {
            swipeInfo = this.addTrack2Data(swipeInfo);
        }
        if (!hasTrack1 && !hasTrack2) {
            swipeInfo.errors.push("Could not read track1 or track2 data");
        }
        return swipeInfo;
    };
    _card_util.prototype.addTrack1Data = function (swipeInfo) {
        var START_SENTINEL = this.TRACK_FORMATS[swipeInfo.trackFormat].TRACK1_START_SENTINEL;
        var END_SENTINEL = this.TRACK_FORMATS[swipeInfo.trackFormat].TRACK1_END_SENTINEL;
        var FIELD_SEPARATOR = this.TRACK_FORMATS[swipeInfo.trackFormat].TRACK1_FIELD_SEPARATOR;
        var trackRaw = this.getTrackData(swipeInfo.magData, START_SENTINEL, END_SENTINEL);
        if (trackRaw === null) {
            swipeInfo.track1 = null;
            swipeInfo.messages.push("No track1 data found");
            return swipeInfo;
        }
        swipeInfo.track1 = {};
        swipeInfo.track1.raw = trackRaw;
        var dataChunks = trackRaw.split(FIELD_SEPARATOR);
        if (dataChunks.length > 1) {
            swipeInfo.track1.format = dataChunks[0].charAt(1);
            swipeInfo.track1.pan = dataChunks[0].substring(2, dataChunks[0].length);
            swipeInfo.track1.maskedPan = swipeInfo.track1.pan.substr(0, 4) + " **** **** " + swipeInfo.track1.pan.substr(swipeInfo.track1.pan.length - 4);
            swipeInfo.track1.cardholder = dataChunks[1].trim();
            swipeInfo.track1.expMonth = dataChunks[2].substring(2, 4);
            swipeInfo.track1.expYear = dataChunks[2].substring(0, 2);
            swipeInfo.track1.expDate = swipeInfo.track1.expMonth + "/" + swipeInfo.track1.expYear;
        }
        else {
            swipeInfo.messages.push("Incomplete track1 data");
        }
        return swipeInfo;
    };
    _card_util.prototype.addTrack2Data = function (swipeInfo) {
        var START_SENTINEL = this.TRACK_FORMATS[swipeInfo.trackFormat].TRACK2_START_SENTINEL;
        var END_SENTINEL = this.TRACK_FORMATS[swipeInfo.trackFormat].TRACK2_END_SENTINEL;
        var FIELD_SEPARATOR = this.TRACK_FORMATS[swipeInfo.trackFormat].TRACK2_FIELD_SEPARATOR;
        var trackRaw = this.getTrackData(swipeInfo.magData, START_SENTINEL, END_SENTINEL);
        if (trackRaw === null) {
            swipeInfo.track2 = null;
            swipeInfo.messages.push("No track2 data found");
            return swipeInfo;
        }
        swipeInfo.track2 = {};
        swipeInfo.track2.raw = trackRaw;
        var dataChunks = trackRaw.split(FIELD_SEPARATOR);
        if (dataChunks.length > 1) {
            // chunk 1
            swipeInfo.track2.pan = dataChunks[0].substring(1, dataChunks[0].length);
            swipeInfo.track2.maskedPan = swipeInfo.track2.pan.substr(0, 4) + " **** **** " + swipeInfo.track2.pan.substr(swipeInfo.track2.pan.length - 4);
            // chunk 2
            swipeInfo.track2.expMonth = dataChunks[1].substr(2, 2);
            swipeInfo.track2.expYear = dataChunks[1].substr(0, 2);
            swipeInfo.track2.expDate = swipeInfo.track2.expMonth + "/" + swipeInfo.track2.expYear;
            swipeInfo.track2.serviceCode = dataChunks[1].substr(4, 3);
        }
        else {
            swipeInfo.messages.push("Incomplete track2 data");
        }
        return swipeInfo;
    };
    _card_util.prototype.getTrackData = function (magData, startSentinel, endSentinel) {
        if (magData.indexOf(startSentinel) !== -1) {
            //str.substring(indexStart[, indexEnd])
            var start = magData.indexOf(startSentinel);
            var end = magData.indexOf(endSentinel, start);
            return magData.substring(start, end + 1);
        }
        return null;
    };
    /*
     * Helper functions
     * */
    function isEmptyString(value) {
        return value !== null && value.length === 0;
    }
    function isDigit(value) {
        return value !== null && (/^\d+$/).test(value);
    }
    function isStringNumeric(value) {
        if (value === null) {
            return false;
        }
        var temp = value.split(/(.)/).filter(isDigit).join("");
        return value === temp;
    }
    return new _card_util();
}));
